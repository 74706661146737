// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"



export const job = createSlice({
  name: "job",
  initialState: {
    id: "",
    title: "",
    slug: "",
    location: "",
    tags: [],
    description: "", // Final HTML post content
    excerpt: "",
    type: 'Full Time',
    basic_skills: ["", "", ""],
    thumbnail: "",
    status: "draft",
    activeWidget: 0,
    addWidgetOpen: false,
    addWidgetOpenIndex: 0,
    stats : {
      isLoading: true,
      isSubmitting : false,
      isError: false,
      errorMessages: [],
    }
  },
  reducers: {
    onFetchStart: (state) => {
      state.stats.isLoading = true
    },
    onPostFetch(state, action) {
      state.title = action.payload.title
      state.description = action.payload.description
      state.slug = action.payload.slug
      state.location = action.payload.location
      state.due_date = action.payload.due_date
      state.excerpt = action.payload.excerpt
      state.thumbnail = action.payload.thumbnail
      state.status = action.payload.status
      state.type = action.payload.type
      state.id = action.payload.id
      console.log(action.payload)
      if (action.payload.basic_skills?.length == 3) {
        state.basic_skills = action.payload.basic_skills
      }

      state.stats = {
        ...state.stats,
        isLoading: false,
        isError: action.payload.isError,
        errorMessages: action.payload.errorMessages
      }
    },
    onChangePost(state, action) {
      if(action.payload.name === "title"){
        state.slug = action.payload.value.toLowerCase().replace(' ', '-').replace(/[^\w-]+/g,'-')
      }
      if (action.payload.name === "basic_skills"){
        state.basic_skills[action.payload.index] = action.payload.value
      }else{
        state[action.payload.name] = action.payload.value
      }
    },
    addNewWidget: (state, action) => {
      //state.content.push(action.payload)
      state.content.splice(state.addWidgetOpenIndex + 1, 0, action.payload)
      state.addWidgetOpen = false
      state.activeWidget += 1
    },
    updateWidget: (state, action) => {
      state.content[action.payload.index] = action.payload.widget
    },
    deleteWidget: (state, action) => {
      state.content.splice(action.payload, 1)
    },
    changeIndex: (state, action) => {
      // change index of widget
      const widget = state.content[action.payload.index]
      state.content.splice(action.payload.index, 1)
      state.content.splice(action.payload.newIndex, 0, widget)
    },
    setAddWidgetOpen: (state, action) => {
      console.log(action.payload)
        state.addWidgetOpen = action.payload.val
        state.addWidgetOpenIndex = action.payload.index
    },
    setActiveWidget: (state, action) => {
      state.activeWidget = action.payload
    }
}
})

export const {
  onPostFetch,
    onChangePost,
    addNewWidget,
    updateWidget,
    deleteWidget,
    changeIndex,
    setAddWidgetOpen,
    setActiveWidget
} = job.actions

export default job.reducer
